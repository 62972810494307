<template>
  <v-dialog
    v-model="forgotShow"
    id="loginForm"
    persistent
    max-width="450"
    overlay-opacity=".5"
  >
    <v-card class="pb-1">
      <v-card-title class="headline align-start justify-space-between">
        <span class="text-uppercase">{{ $t("card.forgot-password") }}</span>
        <v-btn icon @click="forgotShow = false">
          <v-icon>{{ iconClose }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" v-html="$t('text.use-forgot-password-form')">
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="form.username"
              :error-messages="usernameErrors"
              :counter="8"
              label="Username"
              required
              @input="$v.form.username.$touch()"
              @blur="$v.form.username.$touch()"
            ></v-text-field>
              <p class="w-full text-subtitle-2 mb-1 light-blue--text">{{$t('text.please click one of the button below to get token')}}</p>
            <div class="d-flex justify-end mt-1">
              <v-btn
                color="primary"
                :disabled="tokenCountDown > 0"
                class="token__button"
                x-small
                outlined
                @click="getToken('whatsapp')"
              >
                <v-icon class="mr-2" v-if="!tokenCountDown">
                  {{ iconWA }}
                </v-icon>
                <v-icon class="mr-2" v-else>
                  {{ iconTimer }}
                </v-icon>
                {{
                  !tokenCountDown
                    ? $t("button.request-token-wa")
                    : `${$t("text.wait-for")} ${tokenCountDown} ${$t(
                        "text.seconds"
                      )}`
                }}
              </v-btn>
              <v-btn
                color="primary"
                :disabled="tokenCountDown > 0"
                class="token__button ml-2"
                x-small
                outlined
                @click="getToken"
              >
                <v-icon class="mr-2" v-if="!tokenCountDown">
                  {{ iconSms }}
                </v-icon>
                <v-icon class="mr-2" v-else>
                  {{ iconTimer }}
                </v-icon>
                {{
                  !tokenCountDown
                    ? $t("button.request-token-sms")
                    : `${$t("text.wait-for")} ${tokenCountDown} ${$t(
                        "text.seconds"
                      )}`
                }}
              </v-btn>
            </div>
            <v-text-field
              v-model="form.token"
              :error-messages="tokenErrors"
              :counter="4"
              label="Token"
              required
              type="number"
              @input="$v.form.token.$touch()"
              @blur="$v.form.token.$touch()"
            ></v-text-field>

            <v-text-field
              v-model="form.password"
              :error-messages="passwordErrors"
              :counter="15"
              label="Password"
              required
              v-bind:type="passType"
              @input="$v.form.password.$touch()"
              @blur="$v.form.password.$touch()"
            >
              <v-tooltip slot="append" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="primary"
                    v-on="on"
                    v-bind="attrs"
                    v-on:click="togglePass"
                  >
                    <v-icon v-if="passType === 'password'">{{
                      iconEye
                    }}</v-icon>
                    <v-icon v-else>{{ iconEyeClose }}</v-icon>
                  </v-btn>
                </template>
                <span v-if="passType === 'password'">{{
                  $t("tooltip.show-pass")
                }}</span>
                <span v-else>{{ $t("tooltip.hide-pass") }}</span>
              </v-tooltip>
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-end px-5 py-3">
        <v-btn color="primary" medium outlined @click="submit"
          ><v-icon class="mr-2">{{ iconLogin }}</v-icon>
          {{ $t("button.reset-password") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {
  mdiCellphoneMessage,
  mdiClose,
  mdiEye,
  mdiEyeOff,
  mdiLogin,
  mdiTimerSand,
  mdiWhatsapp,
} from "@mdi/js";
import { validationMixin } from "vuelidate";
import { required, maxLength, minLength } from "vuelidate/lib/validators";
import { mapActions, mapState } from "vuex";
const { helpers } = require("vuelidate/lib/validators");

// const niksap = helpers.regex("mob", /^[1-9]\d{6,7}$/);
const goodpass = helpers.regex(
  "mob",
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/
);

const weakPassList = [
  "Abc123",
  "Abc1234",
  "Abc12345",
  "Abc123456",
  "Password",
  "Qwerty123",
  "Qwerty1234",
  "Qwerty12345",
  "Qwerty123456",
  "Million2",
  "Aaron431",
  "Password1",
  "Qqww1122",
];
export default {
  mixins: [validationMixin],

  name: "forgot-password",
  data: () => ({
    iconClose: mdiClose,
    iconLogin: mdiLogin,
    iconSms: mdiCellphoneMessage,
    iconEye: mdiEye,
    iconEyeClose: mdiEyeOff,
    iconTimer: mdiTimerSand,
    iconWA: mdiWhatsapp,
    passType: "password",
    form: {
      username: undefined,
      password: undefined,
      token: undefined,
    },
  }),
  validations: {
    form: {
      username: {
        required,
        maxLength: maxLength(8),
        minLength: minLength(7),
      },
      password: {
        required,
        maxLength: maxLength(15),
        minLength: minLength(5),
        goodpass,
      },
      token: { required, maxLength: maxLength(4), minLength: minLength(4) },
    },
    checkbox: {
      checked(val) {
        return val;
      },
    },
  },

  computed: {
    ...mapState("user", ["tokenCountDown"]),
    forgotShow: {
      get() {
        return this.$store.state.user.forgotShow;
      },
      set(val) {
        this.$store.commit("user/SET_FORGOT_SHOW", val);
      },
    },
    usernameErrors() {
      const errors = [];
      if (!this.$v.form.username.$dirty) return errors;
      !this.$v.form.username.maxLength &&
        errors.push(
          `${this.$t("text.username-max-length-is")} 8 ${this.$t(
            "text.characters"
          )}`
        );
      !this.$v.form.username.minLength &&
        errors.push(
          `${this.$t("text.username-min-length-is")} 7 ${this.$t(
            "text.characters"
          )}`
        );
      !this.$v.form.username.required &&
        errors.push(`${this.$t("text.username-is-required")}`);
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.form.password.$dirty) return errors;
      !this.$v.form.password.maxLength &&
        errors.push(
          `${this.$t("text.password-max-length-is")} 15 ${this.$t(
            "text.characters"
          )}`
        );
      !this.$v.form.password.minLength &&
        errors.push(
          `${this.$t("text.password-min-length-is")} 5 ${this.$t(
            "text.characters"
          )}`
        );
      !this.$v.form.password.required &&
        errors.push(`${this.$t("text.new-password-is-required")}`);
      !this.$v.form.password.goodpass &&
        errors.push(`${this.$t("text.password-must-standard")}`);
      return errors;
    },
    tokenErrors() {
      const errors = [];
      if (!this.$v.form.token.$dirty) return errors;
      !this.$v.form.token.maxLength &&
        errors.push(
          `${this.$t("text.token-max-length-is")} 4 ${this.$t("text.digits")}`
        );
      !this.$v.form.token.minLength &&
        errors.push(
          `${this.$t("text.token-min-length-is")} 4 ${this.$t("text.digits")}`
        );
      !this.$v.form.token.required &&
        errors.push(`${this.$t("text.token-is-required")}`);
      return errors;
    },
  },
  watch: {
    tokenCountDown: {
      handler: function (val) {
        if (!val) return;
        if (val <= 0) return this.setCountDownTime(undefined);
        setTimeout(() => {
          this.setCountDownTime(val - 1);
        }, 1000);
      },
      deep: true,
    },

    // tokenCountDown(val) {
    //   console.log(val);
    //   if (!val) return;
    //   if (val <= 1) return this.setCountDownTime(undefined);
    //   setTimeout(() => {
    //     this.setCountDownTime(val - 1);
    //   }, 1000);
    // },
  },
  methods: {
    ...mapActions("user", ["getSmsToken", "setCountDownTime", "resetPassword"]),
    togglePass() {
      this.passType = this.passType === "password" ? "text" : "password";
    },
    closeForgot() {
      this.resetForm();
      this.$emit("showHideForgot", false);
    },
    getToken(via) {
      this.$v.form.username.$touch();
      if (this.$v.form.username.$anyError) return;
      this.setCountDownTime(60);
      this.getSmsToken({
        username: this.form.username,
        scope: "reset",
        via: via
      });
    },
    submit() {
      this.$v.$touch();
      if (this.$v.form.$anyError) return;
      if (weakPassList.includes(this.form.password)) {
        return this.$dialog.notify.warning(
          this.$t("error.do-not-use-weak-pass"),
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }
      this.resetPassword({
        username: this.form.username,
        password: this.form.password,
        otp: this.form.token,
      });
    },
    resetForm() {
      this.$v.$reset();
      this.form.username = undefined;
      this.form.password = undefined;
      this.form.token = undefined;
    },
  },
};
</script>
<style>
.token__button {
  font-size: 0.85rem !important;
}
</style>