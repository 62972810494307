<template>
  <div>
    <v-dialog
      v-model="loginShow"
      id="loginForm"
      persistent
      max-width="450"
      overlay-opacity=".5"
    >
      <v-card class="pb-1" v-if="!loginAsPerson">
        <v-card-title class="headline align-start justify-space-between">
          <span class="text-uppercase">{{ $t("card.login") }}</span>
          <v-btn icon @click="closeLogin">
            <v-icon>{{ iconClose }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" v-html="$t('text.welcome-login')"> </v-col>
            <v-col cols="12">
              <v-alert
                v-if="loginError === 401 && !processing"
                dense
                type="error"
                :icon="iconAlert"
                class="modal-alert v-alert--narrow v-alert--bubble bottom--left mb-3"
              >
                {{ $t("error.login-fail-desc") }}
              </v-alert>
              <v-alert
                v-if="loginError === 403 && !processing"
                dense
                type="error"
                :icon="iconAlert"
                class="modal-alert v-alert--narrow v-alert--bubble bottom--left mb-3"
              >
                {{ $t("error.account-is-not-valid") }}
              </v-alert>
              <v-alert
                v-if="
                  !processing &&
                  loginError &&
                  loginError !== 403 &&
                  loginError !== 401
                "
                dense
                type="error"
                :icon="iconAlert"
                class="modal-alert v-alert--narrow v-alert--bubble bottom--left mb-3"
              >
                {{ $t("error." + loginError) }}
              </v-alert>

              <v-text-field
                v-model="form.username"
                :error-messages="usernameErrors"
                :counter="10"
                label="Username"
                required
                v-on:input="$v.form.username.$touch()"
                v-on:blur="$v.form.username.$touch()"
                v-on:keyup.enter="submit"
              ></v-text-field>
              <v-text-field
                v-model="form.password"
                :error-messages="passwordErrors"
                :counter="20"
                label="Password"
                required
                v-bind:type="passType"
                v-on:input="$v.form.password.$touch()"
                v-on:blur="$v.form.password.$touch()"
                v-on:keyup.enter="submit"
              >
                <v-tooltip slot="append" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="primary"
                      v-on="on"
                      v-bind="attrs"
                      v-on:click="togglePass"
                    >
                      <v-icon v-if="passType === 'password'">{{
                        iconEye
                      }}</v-icon>
                      <v-icon v-else>{{ iconEyeClose }}</v-icon>
                    </v-btn>
                  </template>
                  <span v-if="passType === 'password'">{{
                    $t("tooltip.show-pass")
                  }}</span>
                  <span v-else>{{ $t("tooltip.hide-pass") }}</span>
                </v-tooltip>
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-end px-5 py-3">
          <v-btn
            color="primary"
            medium
            outlined
            :loading="processing"
            :disabled="processing"
            v-on:click="submit"
            ><v-icon class="mr-2">{{ iconLogin }}</v-icon>
            {{ $t("button.login") }}</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-card class="pb-1" v-if="loginAsPerson">
        <v-card-title class="headline align-start justify-space-between">
          <span class="text-uppercase">{{ $t("card.login") }}</span>
          <v-btn icon @click="closeLogin">
            <v-icon>{{ iconClose }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-card
            outlined
            tile
            color="primary"
            height="100"
            max-height="150"
            class="mb-4 card__select-person rounded"
            @click="loginAs(loginAsPerson[0].key)"
          >
            <v-card-title class="headline white--text">
              <span style="font-size: 1rem">
                {{ $t("text.login-as-fixed-position") }}
              </span>
            </v-card-title>
            <v-card-subtitle class="white--text">
              <span style="font-size: 1.2rem">
                {{ loginAsPerson[0].name }}
              </span>
              <v-icon class="login__as-button">{{ iconClick }}</v-icon>
            </v-card-subtitle>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {
  mdiAccount,
  mdiAccountClock,
  mdiAlertCircleOutline,
  mdiClose,
  mdiCursorDefaultClick,
  mdiEye,
  mdiEyeOff,
  mdiLogin,
} from "@mdi/js";
import { validationMixin } from "vuelidate";
import { required, maxLength, minLength } from "vuelidate/lib/validators";
import { mapState, mapActions } from "vuex";
import { sendDeviceLocation } from "@/constants/config.js";

export default {
  mixins: [validationMixin],
  name: "login",
  data: () => ({
    iconClose: mdiClose,
    iconLogin: mdiLogin,
    iconUser: mdiAccount,
    iconUserStar: mdiAccountClock,
    iconClick: mdiCursorDefaultClick,
    iconAlert: mdiAlertCircleOutline,
    iconEye: mdiEye,
    iconEyeClose: mdiEyeOff,
    passType: "password",
    form: {
      username: undefined,
      password: undefined,
    },
    myDeviceDetail: undefined,
  }),
  validations: {
    form: {
      username: { required, maxLength: maxLength(10), minLength: minLength(7) },
      password: { required, maxLength: maxLength(20), minLength: minLength(5) },
    },
  },
  props: {
    loginShow: { type: Boolean },
  },
  computed: {
    ...mapState("user", [
      "loginAsPerson",
      "loginError",
      "processing",
      "currentUser",
    ]),
    usernameErrors() {
      const errors = [];
      if (!this.$v.form.username.$dirty) return errors;
      !this.$v.form.username.maxLength &&
        errors.push(
          `${this.$t("text.username-max-length-is")} 10 ${this.$t(
            "text.characters"
          )}`
        );
      !this.$v.form.username.minLength &&
        errors.push(
          `${this.$t("text.username-min-length-is")} 7 ${this.$t(
            "text.characters"
          )}`
        );
      !this.$v.form.username.required &&
        errors.push(`${this.$t("text.username-is-required")}`);
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.form.password.$dirty) return errors;
      !this.$v.form.password.maxLength &&
        errors.push(
          `${this.$t("text.password-max-length-is")} 20 ${this.$t(
            "text.characters"
          )}`
        );
      !this.$v.form.password.minLength &&
        errors.push(
          `${this.$t("text.password-min-length-is")} 5 ${this.$t(
            "text.characters"
          )}`
        );
      !this.$v.form.password.required &&
        errors.push(`${this.$t("text.password-is-required")}`);
      return errors;
    },
  },
  methods: {
    ...mapActions("user", [
      "login",
      "signOut",
      "setProcessing",
      "loginAsCancel",
      "setLoginAsPerson",
    ]),
    togglePass() {
      this.passType = this.passType === "password" ? "text" : "password";
    },
    closeLogin() {
      let storageNik = localStorage.getItem("ACTIVE_NIK");

      if (storageNik) return this.$emit("showHideLogin", false);
      this.resetForm();
      this.$emit("showHideLogin", false);
    },
    submit() {
      let storageNik = localStorage.getItem("ACTIVE_NIK");
      if (storageNik || this.currentUser) {
        this.$dialog.notify.error(this.$t("error.multi-login-forbidden"), {
          position: "top-left",
          timeout: 5000,
        });
        return this.$router.push("/");
      }

      //Get public IP
      const { IpregistryClient } = require("@ipregistry/client");
      const client = new IpregistryClient("4g2za3n6kb93a9");
      //Public IP

      this.$v.form.$touch();
      this.setProcessing(true);

      if (this.$v.form.$anyError) {
        return this.setProcessing(false);
      }

      if (sendDeviceLocation === true) {
        //kalau config bilang kirim notif login, maka kirim
        client
          .lookup()
          .then((response) => {
            let res = response.data;
            let clientInfo = {
              ip: res.ip,
              isp: res.carrier.name + " (" + res.connection.organization + ")",
              location:
                res.location.city + " (" + res.location.region.name + ")",
              user_agent: res.user_agent.name,
              device:
                res.user_agent.os.type +
                ", OS : " +
                res.user_agent.os.name +
                " versi : " +
                res.user_agent.os.version,
            };
            return this.normalLogin(clientInfo);
          })
          .catch((error) => {
            console.error(error);
            //error but continue login without notif
            return this.normalLogin(undefined);
            // this.myIp = undefined;
          });
      } else {
         return this.normalLogin(undefined);
      }
    },
    normalLogin(clientInfo) {
      this.login({
        username: this.form.username,
        password: this.form.password,
        client_info: clientInfo,
      }).then(() => {
        if (!this.loginAsPerson && this.currentUser) {
          this.$emit("subscribePushNotification");
          this.$router.push({ path: this.redirect || "/" });
        }
      });
    },
    loginAs(key) {
      this.login({
        username: this.form.username,
        password: this.form.password,
        key: key,
      }).then(() => {
        if (!this.currentUser) return this.resetForm();
        this.$emit("subscribePushNotification");
        this.$router.push({ path: this.redirect || "/" });
        this.setLoginAsPerson(undefined);
      });
    },
    resetForm() {
      this.setProcessing(false);
      this.$v.form.$reset();
      this.username = undefined;
      this.password = undefined;
    },
  },
};
</script>
<style>
.card__select-person {
  cursor: pointer !important;
}
.login__as-button {
  position: absolute !important;
  color: #ffffff38 !important;
  right: 0.5rem;
  bottom: 0.5rem;
  height: 70px;
  width: 70px;
}
.login__as-button .v-icon__svg {
  height: 70px !important;
  width: 70px !important;
}
</style>